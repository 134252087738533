import React from "react";
import OrderHistory from "../components/molecules/OrderHistory/OrderHistory";
import { PageTitle, SEO } from "../modules/seo";

interface ILocation {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  key: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  state: {
    // customer: TroopState.User & IOther & IOrders;
    order: any;
    key: string;
  };
}

const HistoryPage = ({ location }: { location: ILocation }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <SEO title={PageTitle.HistoryPage} />
      <OrderHistory
        order={location?.state?.order}
        key={Number(location?.state?.key)}
      />
      {/* <OrderHistoryPage allOrders={location?.state?.customer?.orders} /> */}
    </div>
  );
};

export default HistoryPage;
